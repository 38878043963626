import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
    path: '/',
    name: '首页',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    component: () => import('../views/index')
  },
  // 减分识题
  {
    path: '/recognize',
    component: () => import('../views/recognize')
  },
  // LD前台
  {
    path: '/front',
    component: () => import('../views/front')
  },
  // 隐私政策
  {
    path: '/policy',
    component: () => import('../views/policy')
  },
  // 营业执照
  {
    path: '/license',
    component: () => import('../views/license')
  },
  // 联系客服
  {
    path: '/customerService',
    component: () => import('../views/customerService')
  },
  // 侵权举报
  {
    path: '/infringement',
    component: () => import('../views/infringement')
  },
  // LD学员
  {
    path: '/learner',
    component: () => import('../views/learner')
  },
  // LD教员
  {
    path: '/coach',
    component: () => import('../views/coach')
  },
  // 关于我们
  {
    path: '/about',
    component: () => import('../views/aboutUs')
  },
  // 申请入驻
  {
    path: '/joinLD',
    component: () => import('../views/joinLD')
  }
]

const router = new VueRouter({
  routes
})

export default router
